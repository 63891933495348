.center-text{
  text-align: center;
}
.wide-button-rounded{
  width: 100%;
  border-radius: 20px;

}
.hr-fine-black{
  color:#06016b;
  background-color:#06016b;
  height:  1px;
}
.invisible{
  color: rgba(252, 252, 252, 0);
}
.contacts-icons{
  color: #8A999A;
}
.nav-bar{
  background-color: #050066;
  color: white;
}


.nav-bar a:hover {
  
  border-bottom: none !important;
  text-decoration: none !important;
  outline: none;
 
}
.nav-bar a:active {  
  border-bottom: .07em solid;; 
  text-decoration: none !important;
  outline: none;
  
  
}
.nav-bar a:focus{
  border-bottom: .07em solid;
  
}

.max-height-200{
  max-height: 200px;
}
.width-120{
  width: 120px;
}
.smaller-text{
  
}
.dropdown-rounded{
  border-radius: 4px;
  
}
.blue-text{
  color:  #06016b !important;
}

/*   #1C2331 bootstrap dark-blue color */